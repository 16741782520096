import React from "react";
import BlockContent from "./block-content";
import Container from "./container";

export function TextBlock(props) {
  return (
    <Container>
      <BlockContent blocks={props.text} />
    </Container>
  );
}
