import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Carousel from "../components/carousel";
import Layout from "../containers/layout";
import { TextBlock } from "../components/text-block";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    frontpage: sanityFrontpage {
      _rawContent
    }
  }
`;

const frontpage = props => {
  const { data, errors } = props;
  
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const frontpage = (data || {}).frontpage;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const { _rawContent } = frontpage;

  const contentBlocks = (_rawContent|| [])
    .map((c) => {
      let el = null;
      switch (c._type) {
        case "carouselPlug":
          el = <Carousel key={c._key} {...c} />;
          break;
        case "textPlug":
          el = <TextBlock key={c._key} {...c} />;
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      {contentBlocks}
    </Layout>
  );
};

export default frontpage;
