import React from "react";
import { Navigation, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Figure } from "./figure";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

class Carousel extends React.Component {
  render() {
    const { images } = this.props;
    
    return (
      <Swiper
        modules={[Navigation, EffectFade]}
        navigation
        effect="fade"
        loop={true}
      >
        {images.map((image) => <SwiperSlide><Figure key={image._key} node={image} /></SwiperSlide>)}
      </Swiper>
    );
  }
};


export default Carousel;