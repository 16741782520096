import { Link } from "gatsby";
import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./header.module.css";

const Header = ({ siteTitle }) => (
  <div className={styles.root}>
    <h1>{siteTitle}</h1>
  </div>
);

export default Header;
